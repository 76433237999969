import {Website} from "../layouts/Website";
import {Box, CircularProgress, Container, Typography} from "@mui/material";
import {PageTitle} from "../components/shared/PageTitle";

import {cardsData} from "../data/cards";
import React, {useEffect} from "react";

import {Download} from "@mui/icons-material";

import qr from "../assets/images/qr_dummy.jpg";
import {CustomButton, SmallCustomButton} from "../assets/mui-styles/styles";

import {hotelsData} from "../data/hotels";
import axios from "axios";
import {API_URL} from "../config";
import {getAuthToken} from "../functions/getAuthToken";

// TODO: gestire hotel dinamico (hotelsData[campoDinamico])

export const AreaPersonale = () => {
    const [loading, setLoading] = React.useState(true)
    const [userInfo, setUserInfo] = React.useState([])
    const [error, setError] = React.useState(null)

    const handleTeamDownload = () => {
        const link = document.createElement('a');
        link.href = cardsData[11]; // your image source
        link.download = 'team_card.png'; // desired name for the downloaded file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleQRDownload = () => {
        const link = document.createElement('a');
        link.href = qr; // your image source
        link.download = 'qr.png'; // desired name for the downloaded file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        getUserInfo()
    }, [])

    const getUserInfo = async () => {
        setLoading(true)
        let token = await getAuthToken()
        await axios.get(`${API_URL}/profile`,{headers: {Authorization: token}})
            .then(res => {
                //console.info(res.data);
                setUserInfo(res.data);
                console.info(userInfo.hotel)
            })
            .catch(err => {
                // TODO : GESTIRE ERRORE
                //console.info(err.response.data.error);
                setError(true);
            })
        setLoading(false)
    }

    return (
        <Website>
            <Container maxWidth={'xxl'} sx={{pb: 2, pt: 6, pl:5}}>
                <PageTitle title={'AREA PERSONALE'} image={'icon-area-personale'}/>
                {loading ?
                    <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>
                        <CircularProgress></CircularProgress>
                    </Box>
                    :
                    <>
                        <Box>
                            <Typography sx={{color: '#e3007d'}} fontSize={'22px'}>
                                IL MIO ITINERARIO
                            </Typography>
                            <ul>
                                {userInfo.letteraDiConvocazione &&
                                    <li>
                                        <a href={userInfo.letteraDiConvocazione} target="_blank">Lettera di convocazione</a>
                                    </li>
                                }
                                {userInfo.bigliettoAndataRitorno &&
                                    <li>
                                        <a href={userInfo.bigliettoAndataRitorno} target="_blank">Biglietteria</a>
                                    </li>
                                }
                                {userInfo.avvicinamentoAndata === 'MEZZI PROPRI' &&
                                    <li>
                                        <p>MEZZI PROPRI</p>
                                    </li>
                                }
                                {userInfo.avvicinamentoAndata === 'TRANSFER NAPOLI - SORRENTO A/R' &&
                                    <li>
                                        <p>TRANSFER NAPOLI - SORRENTO A/R</p>
                                    </li>
                                }
                                {userInfo.avvicinamentoAndata === 'NO' &&
                                    <li>
                                        <p>IN AUTONOMIA</p>
                                    </li>
                                }
                            </ul>
                            <Box sx={{py: 2}}>
                                <Box sx={{borderBottom: '3px solid #e63023', width:'4rem', marginLeft:'3rem'}}/>
                            </Box>
                        </Box>
                        <Box>
                            <Typography sx={{color: '#e3007d'}} fontSize={'22px'}>
                                IL MIO HOTEL
                            </Typography>
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                {/*<img style={{width: '20vw', height:'20vw', marginRight: '10px'}} src={hotelImg} alt={''}/>*/}
                                <div style={{
                                    display: "flex",
                                    flexDirection: 'column',
                                    alignItems: 'left',
                                    justifyContent: 'flex-start'
                                }}>
                                    <h3 style={{
                                        color: '#e3007d',
                                        borderLeft: '3px solid #e63023',
                                        margin: 0,
                                        paddingLeft: '5px'
                                    }}>
                                        {userInfo.hotel ? hotelsData[userInfo.hotel].nome : ' /'}
                                    </h3>
                                    <p style={{marginTop: 0, marginBottom: 0}}><span
                                        style={{fontWeight: 'bold'}}>Indirizzo</span>: {userInfo.hotel ? hotelsData[userInfo.hotel].indirizzo : ' /'} </p>
                                    <p style={{marginTop: 0, marginBottom: 0}}><span style={{fontWeight: 'bold'}}>Tel</span>: {userInfo.hotel ? hotelsData[userInfo.hotel].tel : ' /'}</p>
                                    <p style={{marginTop: 0, marginBottom: 0}}><span
                                        style={{fontWeight: 'bold'}}>Sito web</span>:  <a href={userInfo.hotel ? hotelsData[userInfo.hotel].website : ' /'} target="_blank">{userInfo.hotel ? hotelsData[userInfo.hotel].website : ' /'}</a>
                                    </p>

                                    <br/>

                                    <p style={{marginTop: 0, marginBottom: 0}}><span
                                        style={{fontWeight: 'bold'}}>Accomodation</span>: {userInfo.camera ? userInfo.camera : ' / '}
                                    </p>
                                    <p style={{marginTop: 0, marginBottom: 0}}><span style={{fontWeight: 'bold'}}>Check-in</span>: {userInfo.checkIn ? new Date(userInfo.checkIn).toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit'}) : ' / '} </p>
                                    <p style={{marginTop: 0, marginBottom: 0}}><span
                                        style={{fontWeight: 'bold'}}>Orario check-in</span>: dalle ore 15:00
                                    </p>
                                    <p style={{marginTop: 0, marginBottom: 0}}><span
                                        style={{fontWeight: 'bold'}}>Check-out</span>: {userInfo.checkOut ? new Date(userInfo.checkOut).toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit'}) : ' / '}
                                    </p>
                                    <p style={{marginTop: 0, marginBottom: 0}}><span style={{fontWeight: 'bold'}}>Orario check-out</span>: entro le ore 10:00
                                    </p>
                                    <p style={{marginTop: 0, marginBottom: 0}}><span
                                        style={{fontWeight: 'bold'}}>Extra</span>: a carico di ogni singolo partecipante</p>
                                </div>
                            </Box>
                            <Box sx={{py:2}}>
                                <Box sx={{borderBottom:'3px solid #e63023', width:'4rem', marginLeft:'3rem'}}/>
                            </Box>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Typography sx={{color: '#e3007d'}} fontSize={'22px'}>
                                IL MIO QRCODE
                            </Typography>
                            <img src={userInfo.qrCodeUrl} alt={'qr code'} style={{width: '30vh'}}/>
                            <SmallCustomButton sx={{marginTop: '15px'}} onClick={handleQRDownload}>
                                <Download></Download> DOWNLOAD
                            </SmallCustomButton>
                            <Box sx={{py:2}}>
                                <Box sx={{borderBottom:'3px solid #e63023', width:'4rem', marginLeft:'3rem'}}/>
                            </Box>
                        </Box>

                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Typography sx={{color: '#e3007d'}} fontSize={'22px'}>
                                LA MIA SQUADRA
                            </Typography>
                            <img src={cardsData[11]} alt={'team card'} style={{width: '30vh'}}/>
                            <SmallCustomButton sx={{marginTop: '15px'}} onClick={handleTeamDownload}>
                                <Download></Download> DOWNLOAD
                            </SmallCustomButton>
                        </Box>
                    </>
                }

            </Container>
        </Website>
    )
}