export const dressMap = [
    {
        title: 'Quest’anno il motto è "dress as you like" - non c\'è un vero e proprio dress code, ma la libertà di esprimersi attraverso il proprio stile!',
        description:
            <>
                Ecco qualche indicazione in merito così da essere pronti su cosa mettere in valigia.
                <br/>
                <br/>
                <span style={{textDecoration: 'underline'}}>1° GIORNO</span>
                <br/>
                <br/>
                La prima sera sarà caratterizzata da una prima plenaria e a seguire da una <span style={{fontWeight:'bold', color:'#E51576'}}>cena a bordo piscina</span>. Che si
                scelga un look elegante oppure più casual, consigliamo però di non dimenticare un maglioncino o una
                giacca perché, si sa, la sera le temperature potrebbero abbassarsi un po’.
                <br/>
                <br/>
                <span style={{textDecoration: 'underline'}}>2° GIORNO</span>
                <br/>
                <br/>
                La seconda giornata inizierà con un secondo meeting per il quale suggeriamo un <span style={{fontWeight:'bold', color:'#E51576'}}>giusto mix tra tempo libero e business</span>, disinvolto ma comunque professionale.
                <br/>
                Dopo la plenaria, nel pomeriggio, svolgeremo un’attività di team building tra le vie di Sorrento, pertanto non dimenticare <span style={{fontWeight:'bold', color:'#E51576'}}>comodità e praticità</span>.
                <br/>
                <br/>
                La seconda sera, invece, sarà una <span style={{fontWeight:'bold',color:'#E51576'}}>festa partenopea autentica</span>, si cenerà e ballerà sotto le stelle, quindi consigliamo di scegliere abiti comodi e colorati che permettano di divertirsi senza pensieri.
            </>
    }
]
